import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Buscador from '../Buscador';

export default class CorteDiario extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        let fecIni = fec.yyyymmdd('-');
        this.state = {
            cargando:false,
            popoverProvOpen:false,
            Temporada:this.props.temp,
            empresa:2,
            lote:0,
            empId:0,
            cant:0,
            fecha:fecIni,
            lotes:[],
            sectores:[],
            empleado:{},
            nomEmp:'',
            detalle:[]
        };
        this.cargarLotes();
    }
    cargarEmpleado = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/cargarEmpleado/${this.state.empId}?empresa=${this.state.empresa}`;
        let resp = await fetch(url);
        const empleado = await resp.json();
        const nomEmp = empleado.Nombre + ' ' + empleado.ApePat + ' ' + empleado.ApeMat;
        this.setState({cargando:false, empleado, nomEmp});
        document.getElementById("cant").focus();
    }
    empSel = (item) =>{
        this.setState({popoverProvOpen:false, empId: item.I, nomEmp:item.N},() =>{
            this.cargarEmpleado();
        });
    }
    onKeyPressEmp = (e) =>{
        if(e.which == 13)
           this.cargarEmpleado();
    }
    onKeyPressCant = (e) =>{
        if(e.which == 13){
            let cant = parseInt(this.state.cant);
            if(isNaN(this.state.cant) || isNaN(cant) || cant === 0){
                Swal.fire('Atencion','Cantidad de cubetas incorrecta','error');
                return;
            }
            this.agregar(cant);
        }
    }
    toggleEmp = () => {
        this.setState({popoverProvOpen: !this.state.popoverProvOpen});
    }    
    cargarSectores = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/sectoresLoteProg?fecha=${this.state.fecha}&lote=${this.state.Lote}`;
        const resp = await fetch(url);
        const sectores = await resp.json();
        console.log(sectores);
        this.setState({cargando:false, sectores });
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/lotesCorteTemp/${this.state.Temporada}`;
        const resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarDetalle = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/nomina/empleadosCorteDia?empresa=${this.state.empresa}&fecha=${this.state.fecha}`;
        const resp = await fetch(url);
        const detalle = await resp.json();
        this.setState({cargando:false, detalle });
    }
    imprimir = () =>{
        let url = `${this.props.urlws}/agricolarpt/corteDiario?empresa=${this.state.empresa}&fecha=${this.state.fecha}`;
        window.open(url, '', '');    
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            if(campo === "Lote")
                this.cargarSectores();
        });
    }
    detalleClick = (emp) =>{
        this.setState({empId : emp.Id, nomEmp : emp.Nombre});
        document.getElementById("emp").focus();
    }
    agregar = (cant) =>{
        let sector = 0;
        let tabla = 0;//   <option key={i.Id} value={i.Id}>Sector {i.Sector} // Tabla {i.Tabla} // {i.Nom}</option>        
        for (let i = 0; i < this.state.sectores.length; i++) {
            if(this.state.sectores[i].Id == this.state.sector){
                sector = this.state.sectores[i].Sector;
                tabla = this.state.sectores[i].Tabla;
            }
        }
        //alert("sector : " + sector + "  tabla : " + tabla);
        for (let i = 0; i < this.state.detalle.length; i++) {
            if(this.state.detalle[i].Id == this.state.empId){
                this.state.detalle[i].Cant += cant;
                this.setState({cargando:false});
                return;
            }
        }
        this.state.detalle.push({
            Id : this.state.empId,
            Nombre : this.state.nomEmp,
            Cant : cant
        });
        this.setState({cargando:false});
    }
    render () {
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div className="row">
                    <div className="col-2">
                        <FormGroup>
                            <span>Del dia</span><br />
                            <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Lote</span><br />
                            <Input size="sm" type="select" name="Lote" value={this.state.Lote} onChange={this.onChange}>
                                <option key="0" value="">Especifique</option>
                                {this.state.lotes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarDetalle}>Cargar</Button>
                                <span>&nbsp;&nbsp;</span>
                                <Button outline color='success' size="sm" onClick={this.imprimir}>
                                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/>
                                </Button>
                            </FormGroup>
                        </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <span>Sector</span><br />
                            <Input size="sm" type="select" name="sector" value={this.state.sector} onChange={this.onChange}>
                                <option key="0" value="0">Especifique</option>
                                {this.state.sectores.map((i, k) =>
                                    <option key={i.Id} value={i.Id}>Sector {i.Sector} // Tabla {i.Tabla} // {i.Nom}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <span>Empleado</span>
                        <InputGroup>
                            <Input size="sm" type="text" name="empId" value={this.state.empId} onChange={this.onChange} onKeyPress={this.onKeyPressEmp} id="emp" autoFocus />
                            <InputGroupAddon addonType="append">
                                <Button size="sm" color="success" id="btnBuscarEmp">
                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                </Button>
                                <Popover trigger="legacy" placement="right" isOpen={this.state.popoverProvOpen} target="btnBuscarEmp" toggle={this.toggleEmp} >
                                    <PopoverBody>
                                        <Buscador onSelect={this.empSel} url={this.props.urlws + "/nomina/empleadosLista?empresa=" + this.state.empresa + "&filtro="} />
                                    </PopoverBody>
                                </Popover>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="col-4">
                        <FormGroup>
                            <span>&nbsp;</span><br />
                            <Input size="sm" type="text" name="nomEmp" value={this.state.nomEmp} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>Cubetas</span><br />
                            <Input size="sm" type="text" id="cant" name="cant" value={this.state.cant} onChange={this.onChange} onKeyPress={this.onKeyPressCant} />
                        </FormGroup>
                    </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 60 }}>No.</th>
                                <th>Nombre</th>
                                <th style={{ width: 60 }}>Cubetas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} onClick={this.detalleClick} />
                            ))}
                        </tbody>
                    </Table>
                </div>                
            </Container>
        )
    }
}
class DetalleRow extends React.Component{
    onClick = () =>{
        this.props.onClick(this.props.renglon);
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr onClick={this.onClick}>
            <td className="numero">{renglon.Id}</td>
            <td>{renglon.Nombre}</td>
            <td className="numero rojo" >{renglon.Cant}</td>
        </tr>
        );
    }
}