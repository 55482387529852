import React from 'react';
import { Nav, Navbar, NavItem, NavLink, NavbarBrand, Button } from 'reactstrap';
import SideBarMenu from './SideBarMenu';
import Login from './Login';
import Insumos from './inv/Insumos';
import Productos from './inv/Productos';
import Entradas from './inv/Entradas';
import Salidas from './inv/Salidas';
import Kardex from './inv/Kardex';
import ReportesAgri from './agri/ReportesAgri';
import ReportesInv from './inv/ReportesInv';
import Clientes from './cxc/Clientes';
import Proveedores from './cxp/Proveedores';
import TipoCambio from './cxp/TipoCambio';
import EdoCtaCxP from './cxp/EdoCtaCxP';
import NotaCredPend from './cxp/NotaCredPend';
import Relacionar from './cxp/Relacionar';
import PagosCxP from './cxp/PagosCxP';
import Destinos from './agri/Destinos';
import Cultivos from './agri/Cultivos';
import CultivosLote from './agri/CultivosLote';
import Variedades from './agri/Variedades';
import Lotes from './agri/Lotes';
import Actividades from './agri/Actividades';
import ActDiariasMaq from './agri/ActDiariasMaq';
import ConsumoDiesel from './agri/ConsumoDiesel';
import Muestreos from './agri/Muestreos';
import AgriReportes from './agri/AgriReportes';
import Requisiciones from './compras/Requisiciones';
import OrdenesCompra from './compras/OrdenesCompra';
import Compras from './compras/Compras';
import XmlProvs from './compras/XmlProvs';
import ContaRepos from './conta/ContaRepos';
import Dividendos from './conta/Dividendos';
import Auxiliar from './conta/Auxiliar';
import Analitica from './conta/Analitica';
import Polizas from './conta/Polizas';
import Cheques from './conta/Cheques';
import ReportesNom from './nom/ReportesNom';
import Empleados from './nom/Empleados';
import NomDeptos from './nom/NomDeptos';
import Sucursales from './nom/Sucursales';
import Credenciales from './nom/Credenciales';
import Nominas from './nom/Nominas';
import CapturaEmpleado from './nom/CapturaEmpleado';
import ConsultaEmpleado from './nom/ConsultaEmpleado';
import ActividadesCultivo from './nom/ActividadesCultivo';
import ActividadesNom from './nom/ActividadesNom';
import CapturaDiariaAg from './nom/CapturaDiariaAg';
import Corte from './arandano/Corte';
import CorteDiario from './arandano/CorteDiario';
import CorteSem from './arandano/CorteSem';
import Empaque from './arandano/Empaque';
import EmpaqueSem from './arandano/EmpaqueSem';
import ConcentradoSemanal from './arandano/ConcentradoSemanal';
import Usuarios from './config/Usuarios';
import Notifica from './Notifica';
import Menu from './Menu';
import Vendedores from './embarques/Vendedores';
import DestinosEmb from './embarques/Destinos';
import Choferes from './embarques/Choferes';
import Transportistas from './embarques/Transportistas';
import OrdenEmbarque from './embarques/OrdenEmbarque';
import LiqPapa from './embarques/LiqPapa';
import LiqGranos from './granos/LiqGranos';
import classnames from 'classnames';
class App extends React.Component {
  constructor(props) {
    super(props);
    let urlws = '';// 'https://www.prod.isaerpweb.com';
    //urlws = 'http://localhost:7115';
    this.state = {
      u: { id: 0, nombre: '', admin: false },
      lg: false,
      opc: '',
      txt:'',
      temp:'2021',
      nombreEmpresa:'Agricola Tepachoipa',
      urlws : urlws,
      activeTab:0,
      tabs:['Inicio'],
      componenteActivo:0,
      componentes: [
          <div><h1>Agricola Tepachoipa</h1></div>,
      ]
    }
  }
  opcionSeleccionada = (opc, txt) => {
    const rpt = opc;
    let urlws = this.state.urlws;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if(this.state.tabs[i] === txt ){
        this.setState({activeTab:i});
        return;
      }
    }
    if(opc === "rptBalanza" || opc === "rptBalance" || opc === "rptAnalitica" || opc === "rptAuxiliar" || opc === "rptResultados" || opc === "rptVerificador"  || opc === "rptPolizas")
      opc = "rptConta"
    if(opc === "rptLstEnt" || opc === "rptLstSal" || opc === "rptSdosAlm" || opc === "rptExisAct" || opc === "rptKardex"  || opc === "rptExis")
      opc = "rptInv"
    if(opc === "rptCosechaGranos" || opc === "rptRemGranos" || opc === "rptRemGranosLiq" || opc == "rptCostoLoteCult" || opc == "rptAgriActLote" || opc == "rptAgriAct")
      opc = "rptAgri"
    if(opc === "rptNomActFecCon" || opc === "rptNomActFecDet" )
      opc = "rptNom"
    if (opc === "agroquimicos")
      this.agregar(<Insumos urlws={urlws} usuario={this.state.u} />);
    if (opc === "compras")
      this.agregar(<Compras urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "productos")
      this.agregar(<Productos urlws={urlws} usuario={this.state.u} />);
    if (opc === "requisicion")
      this.agregar(<Requisiciones urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "ordencompra")
      this.agregar(<OrdenesCompra urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "entAgro")
      this.agregar(<Entradas depto={1} urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "salAgro")
      this.agregar(<Salidas depto={1} urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "entRef")
      this.agregar(<Entradas depto={2} urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "salRef")
      this.agregar(<Salidas depto={2} urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "kardex")
      this.agregar(<Kardex urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "xmls")
      this.agregar(<XmlProvs urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    
      if (opc === "rptInv")
      this.agregar(<ReportesInv rpt={rpt} urlws={urlws} usuario={this.state.u} />);
    if (opc === "rptAgri")
      this.agregar(<ReportesAgri rpt={rpt} urlws={urlws} usuario={this.state.u} />);
    if (opc === "rptNom")
      this.agregar(<ReportesNom rpt={rpt} urlws={urlws} usuario={this.state.u} />);
    
    if (opc === "clientes")
      this.agregar(<Clientes urlws={urlws} usuario={this.state.u} />);

    if (opc === "proveedores")
      this.agregar(<Proveedores urlws={urlws} usuario={this.state.u} />);
    if (opc === "tipoCambio")
      this.agregar(<TipoCambio urlws={urlws} usuario={this.state.u} />);
    if (opc === "edoCtaCxp")
      this.agregar(<EdoCtaCxP urlws={urlws} usuario={this.state.u} />);
    if (opc === "cxpNcPend")
      this.agregar(<NotaCredPend urlws={urlws} usuario={this.state.u} />);
    if (opc === "relArtisProv")
      this.agregar(<Relacionar urlws={urlws} usuario={this.state.u} />);
    if (opc === "destinos")
      this.agregar(<Destinos urlws={urlws} usuario={this.state.u} />);
    if (opc === "cultivos")
      this.agregar(<Cultivos urlws={urlws} usuario={this.state.u} />);
    if (opc === "variedades")
      this.agregar(<Variedades urlws={urlws} usuario={this.state.u} />);
    if (opc === "lotes")
      this.agregar(<Lotes urlws={urlws} usuario={this.state.u} />);
    if (opc === "loteCult")
      this.agregar(<CultivosLote urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "actividades")
      this.agregar(<Actividades urlws={urlws} usuario={this.state.u} />);
    if (opc === "actividadesNom")
      this.agregar(<ActividadesNom urlws={urlws} usuario={this.state.u} />);
    if (opc === "rptDieselMes")
      this.agregar(<AgriReportes urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "nominas")
      this.agregar(<Nominas urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "capnom")
      this.agregar(<CapturaEmpleado urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "connom")
      this.agregar(<ConsultaEmpleado urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "muestreos")
      this.agregar(<Muestreos urlws={urlws} usuario={this.state.u} />);
    if (opc === "diarioMaq")
      this.agregar(<ActDiariasMaq urlws={urlws} usuario={this.state.u} />);
    if (opc === "conDiesel")
      this.agregar(<ConsumoDiesel urlws={urlws} usuario={this.state.u} />);
    if (opc === "rptConta")
      this.agregar(<ContaRepos rpt={rpt} titulo={this.state.txt} urlws={urlws} usuario={this.state.u} />);
    if (opc === "dividendos")
      this.agregar(<Dividendos rpt={rpt} titulo={this.state.txt} urlws={urlws} usuario={this.state.u} />);
    if (opc === "conAna")
      this.agregar(<Analitica rpt={rpt} titulo={this.state.txt} urlws={urlws} usuario={this.state.u} />);
    if (opc === "conAuxi")
      this.agregar(<Auxiliar rpt={rpt} titulo={this.state.txt} urlws={urlws} usuario={this.state.u} />);
    if (opc === "polizas")
      this.agregar(<Polizas rpt={rpt} titulo={this.state.txt} urlws={urlws} usuario={this.state.u} />);
    if (opc === "cheques")
      this.agregar(<Cheques rpt={rpt} titulo={this.state.txt} urlws={urlws} usuario={this.state.u} />);
    if (opc === "cxpPagos")
      this.agregar(<PagosCxP urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "empleados")
      this.agregar(<Empleados urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "sucursales")
      this.agregar(<Sucursales urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "nomDeptos")
      this.agregar(<NomDeptos urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "cred")
      this.agregar(<Credenciales urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "actsCultivos")
      this.agregar(<ActividadesCultivo urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "capNomAg")
      this.agregar(<CapturaDiariaAg urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "confUsuarios")
      this.agregar(<Usuarios urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "movCorteAran")
      this.agregar(<CorteDiario urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "rptAgriCorte")
      this.agregar(<Corte urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "rptAgriEmpaque")
      this.agregar(<Empaque urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "rptAgriCorteSem")
      this.agregar(<CorteSem urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "rptAgriEmpaqueSem")
      this.agregar(<EmpaqueSem urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "rptAgriCorteEmpSem")
      this.agregar(<ConcentradoSemanal urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "vendedores")
      this.agregar(<Vendedores urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "transportistas")
      this.agregar(<Transportistas urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "choferes")
      this.agregar(<Choferes urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "destinosEmb")
      this.agregar(<DestinosEmb urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "movEmbPapa")
      this.agregar(<OrdenEmbarque urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "movLiqGranos")
      this.agregar(<LiqGranos urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    if (opc === "movLiqPapa")
      this.agregar(<LiqPapa urlws={urlws} usuario={this.state.u} temp={this.state.temp} />);
    this.setState({
      tabs: this.state.tabs.concat(txt)
    });

    opc = "";
    this.setState({ opc, txt });
  }
  iniciar = (u) => {
    const usu = { id: u.Id, nombre: u.Nombre, admin: u.Admin };
    this.setState({ lg: true, u: usu });
  }
  agregar = (componente) => {
    this.setState({
      activeTab:this.state.tabs.length,
      componentes: this.state.componentes.concat(componente)
    });
  };
  toggle = (i) =>{
    const txt = this.state.tabs[i];
    this.setState({activeTab:i, componenteActivo:i, txt});
  }
  cerrar = (i) =>{
    if(i == 0)
       return;
    //this.state.componentes.splice(i,1);
    //this.state.tabs.splice(i,1);
    this.state.componentes[i] = null;
    this.state.tabs[i] = null;
    this.setState({activeTab:this.state.tabs.length-1});
  }
  render() {
    let urlws = this.state.urlws;
    let {opc} = this.state;
    if(opc === "rptBalanza" || opc === "rptBalance" || opc === "rptAnalitica" || opc === "rptAuxiliar" || opc === "rptResultados" || opc === "rptVerificador"  || opc === "rptPolizas")
      opc = "rptConta"
    if(opc === "rptLstEnt" || opc === "rptLstSal" || opc === "rptSdosAlm" || opc === "rptExisAct" || opc === "rptKardex"  || opc === "rptExis")
      opc = "rptInv"
    if(opc === "rptCosechaGranos" || opc === "rptRemGranos" || opc === "rptRemGranosLiq" || opc === "rptCostoLoteCult")
      opc = "rptAgri"
    const estilo = { visibility: this.state.lg ? 'visible' : 'hidden' };
    if(this.state.lg)
      document.body.classList.add('body');
    return (
      <div>
        {this.state.lg ? null : <Login iniciar={this.iniciar} nombre={this.state.nombreEmpresa} urlws={urlws} />}
        <div style={estilo}>
          <div className="row">
            <div className="col-2">
              <img src="/imagenes/logo.png" height="80" />
            </div>
            <div className="col">
              <div className="row">
                <div className="col-10">
                  <Menu opcionSeleccionada={this.opcionSeleccionada} usuario={this.state.u} />
                </div>
                <div className="col">
                  <span style={{ textAlign:'left', color: '#fff', fontWeight:'bolder' }}>usuario:{this.state.u.nombre}</span>
                </div>
              </div>
                <div className="row">
                  <div className="col-7">
                    <span className="opcionActual">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.txt}</span>
                  </div>
                  <div className="col-2">
                    <Notifica url={`${this.state.urlws}/invMovs/nuevasReq/1`} milisegundos={10000} texto="requisiciones por cotizar"/>
                  </div>
                  <div className="col-2">
                    <Notifica url={`${this.state.urlws}/invMovs/nuevasOrdenes/1`} milisegundos={10000} texto="Ord. de compras por enviar"/>
                  </div>
                </div>
            </div>
          </div>
          {this.state.lg ?
          <div id="content" style={{ width: '90%' }}>
              <div>
              <Nav tabs>
                {this.state.tabs.map((o,i)=>
                  o == null ? null :
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === i })}
                      onClick={() => { this.toggle(i); }}>
                        <span>{o}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        { i > 0 &&
                        <a href="#"><b><span onClick={()=>{this.cerrar(i)}}><img src="/imagenes/circle-x.png" alt="cerrar"/></span></b></a>
                        }
                        </NavLink>
                  </NavItem>
                )}
                </Nav>
              </div>    


            {
              this.state.componentes.map((o,i) => {
                const k = "k" + i;
                const visible = i == this.state.activeTab ?'block':'none';
                return <div key={k} style={{display:visible}}>{o}</div>
            })
            }
          </div>
          :null
          }
        </div>
      </div>
    )
  }
}
export default App;