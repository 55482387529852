import React from 'react';
import { Navbar,Row, Table, Progress, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Nominas extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            detalle:false,
            mostrarModal:false,
            mostrarModalImp:false,
            Temporada:this.props.temp,
            empresa:1,
            tipo:'S',
            ejercicio:2021,
            fechaIni:fecIni,
            fechaFin:fecFin,
            fechaPago:fecFin,
            nueva:false,
            calculo:[], 
            errores:0,
            correctos:0,
            per : 0, 
            ded : 0,
            tot : 0,
            calculando:false,
            mostrarCalculo:false,
            empresas:[],
            tipos:[],
            nominas:[],
            usuarios:[],
            ejercicios:[],
            temporadas:[],
            recibos:[],
            calculos:[],
            puntos:[],
            punto:0,
            nomina:0,
            temp:'',
            nomEmp:'',
            progreso:0,
            objNomina:{}
        };
        this.cargarCatalogos();
        this.cargarPuntosCalculo();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/empresa/empresasNomina`;
        let resp = await fetch(url);
        const empresas = await resp.json();
        url = `${this.props.urlws}/conta/ejercicios`;
        resp = await fetch(url);
        const ejercicios = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        this.setState({cargando:false, empresas, ejercicios, temporadas });
        this.cargarTipos();
    }
    cargarTipos = async () => {
        this.setState({cargando:true});
        let {tipo} = this.state;
        let url = `${this.props.urlws}/nomina/tipos/${this.state.empresa}`;
        let resp = await fetch(url);
        const tipos = await resp.json();
        if(tipos.length > 0)
           tipo = tipos[0].I;
        this.setState({cargando:false, tipos, tipo });
    }
    cargarPuntosCalculo = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/puntosCalculo?emp=2`;
        let resp = await fetch(url);
        const puntos = await resp.json();
        this.setState({cargando:false, puntos });
    }
    cargarMovs = async () =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        if(emp === 0){
            Swal.fire('Atencion','Especifique la empresa','error');
            return;
        }
        if(emp === 'X'){
            Swal.fire('Atencion','Especifique el tipo de nomina','error');
            return;
        }
        if(ejer === 0){
            Swal.fire('Atencion','Especifique el año','error');
            return;
        }
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/nominas?emp=${emp}&ejer=${ejer}&tipo=${tipo}`;
        let resp = await fetch(url);
        const nominas = await resp.json();
        this.setState({cargando:false, nominas });
    }
    guardar = async (obj) =>{
        const mov = {
            Empresa : this.state.empresa,
            Ejercicio : this.state.ejercicio,
            Nomina : this.state.nomina,
            Tipo : this.state.tipo,
            Inicio : this.state.fechaIni,
            Fin : this.state.fechaFin,
            FechaPago : this.state.fechaPago
        }
        const url = this.props.urlws +"/nomina/wsguardarNomina";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false,mostrarModal:false});
            this.cargarMovs();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            if(campo === "empresa")
               this.cargarTipos();
        });
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    handleCloseImp = () =>{
        this.setState({mostrarModalImp:false});
    }
    regresar = () =>{
        this.setState({detalle:false});
    }
    editarMov = (mov) =>{
        let nomina = mov.Nomina;
        let fechaIni = mov.Inicio;
        let fechaFin = mov.Fin;
        let fechaPago = mov.FechaPago;
        let nueva = false;
        if(fechaPago === '')
           fechaPago = mov.Fin;
        this.setState({mostrarModal:true, fechaIni, fechaFin,fechaPago, nomina, nueva});
    }
    imprimir = (mov) =>{
        this.setState({mostrarModalImp:true, nomina:mov.Nomina});
    }
    imprimirB = () =>{    
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        const nom = this.state.nomina;
        const pto = this.state.punto;
        let url = this.props.urlws + `/nomina/imprimirNomina?emp=${emp}&ejer=${ejer}&nom=${nom}&tipo=${tipo}&punto=${pto}`;
        window.open(url, '', '');    
    }
    calcular = async(mov) =>{    
        const emp = this.state.empresa;
        const ejer = this.state.ejercicio;
        const pto = this.state.punto;
        const temp = mov.Temp; 
        const nom = mov.Nomina;
        let url = this.props.urlws + `/nomina/calcular?empresa=${emp}&ejercicio=${ejer}&semana=${nom}&temp=${temp}&punto=${pto}`;
        this.setState({cargando:true, calculando:true});
        let myVar = setInterval(this.progreso, 400);
        let resp = await fetch(url);
        const res = await resp.text();
        clearTimeout(myVar);
        this.setState({cargando:false, calculando:false});
        Swal.fire('Atencion',res);
    }
    progreso = async(mov) =>{    
        let url = this.props.urlws + "/home/SomeTaskProgress/Calculo";
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({progreso:Number(res)});
    }
    
    imprimirConcentrado = (tipoEmp,excel) =>{
        const ex = excel ?"S":"N";
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        const nom = this.state.objNomina.Nomina;
        const pto = 0;//this.state.punto;
        let url = this.props.urlws + `/nomina/imprimirConcentrado?emp=${emp}&ejer=${ejer}&nom=${nom}&tipo=${tipo}&punto=${pto}&excel=${ex}&tipoEmp=${tipoEmp}`;
        window.open(url, '', '');    
    }
    imprimirAcumulado = (tipoEmp,excel) =>{    
        const ex = excel ?"S":"N";
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        const nom = this.state.objNomina.Nomina;
        const pto = 0;//this.state.punto;
        let url = this.props.urlws + `/nomina/imprimirAcumulado?emp=${emp}&ejer=${ejer}&nom=${nom}&tipo=${tipo}&punto=${pto}&excel=${ex}&tipoEmp=${tipoEmp}`;
        window.open(url, '', '');    
    }
    imprimirRecibo = (rec) =>{
        const emp = this.state.empresa;
        const cve = rec.Ser; 
        const num = rec.Rec;
        let url = this.props.urlws + `/nomina/generarRecibo?empresa=${emp}&serie=${cve}&numero=${num}`;
        window.open(url, '', '');    
    }
    timbrarTodo = async() =>{
        this.state.errores = 0;
        this.state.correctos = 0;
        for (let i = 0; i < this.state.recibos.length; i++) 
            if(this.state.recibos[i].Timb != 'S')
              await this.timbrar(this.state.recibos[i],false);
        Swal.fire('Atencion','Timbrados ' + this.state.correctos + ' // errores : ' + this.state.errores ,'success');
    }
    timbrar = async(rec,avisar) =>{
        const emp = this.state.empresa;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/timbrar?emp=${emp}&ser=${rec.Ser}&num=${rec.Rec}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            if(avisar)
               Swal.fire('Atencion','Se timbro el recibo ' + rec.Rec,'success');
            for (let i = 0; i < this.state.recibos.length; i++) 
               if(this.state.recibos[i].Rec === rec.Rec)
                  this.state.recibos[i].Timb = 'S';
            this.setState({cargando:true});
            this.setState({cargando:false});
            this.state.correctos++;
        }
        else{
            this.state.errores++;
            if(avisar)
               Swal.fire('Atencion',res,'error');
        }
    }
    mostrarError = async(rec) =>{
        const emp = this.state.empresa;
        const ejer = this.state.ejercicio;
        const mov = this.state.objNomina;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/errorTimbrado?emp=${emp}&ser=${rec.Ser}&num=${rec.Rec}&ejer=${ejer}&nom=${mov.Nomina}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "")
            Swal.fire('Atencion','No se encontro mensaje de error de timbrado','info');
        else
            Swal.fire('Error de timbrado',res,'info');
    }
    cambiarStatus = async(mov) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Cerrar la nomina " + mov.Nomina +" ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
              if(result.value)
                this.acumular(mov);
          })        
    }
    acumular = async(mov) => {
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        this.setState({cargando:true});
        let url = this.props.urlws + `/nomina/acumular?emp=${emp}&ejer=${ejer}&nom=${mov.Nomina}&tipo=${tipo}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Se acumulo la nomina ' + mov.Nomina,'success');
            this.cargarMovs();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    eliminarEmp = async(emp) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/eliminarCaptura?empresa=${this.state.empresa}&empleado=${emp.Id}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Se elimino de la nomina al empleado ' + emp.Nom,'success');
            this.detalleMov(this.state.objNomina);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    verCalculoEmp = async(emp) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/cargarNomCalcEmp?empresa=${this.state.empresa}&empleado=${emp.Id}`;
        let resp = await fetch(url);
        let per = 0
        let ded = 0
        const calculo = await resp.json();
        for (let i = 0; i < calculo.length; i++) {
            if(calculo[i].T === 'P')
                per += calculo[i].Importe;
            if(calculo[i].T === 'D')
                ded += calculo[i].Importe;
        }
        const tot = per - ded;
        this.setState({cargando:false, calculo, per, ded, tot,nomEmp:emp.Nom, mostrarCalculo:true});
    }
    cerrarModalCalculo = () =>{
        this.setState({mostrarCalculo:false})
    }
    detalleMov = async(mov) =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        this.setState({cargando:true});
        if(mov.Cerrada){
            let url = `${this.props.urlws}/nomina/recibos?emp=${emp}&ejer=${ejer}&nom=${mov.Nomina}&tipo=${tipo}&tipoEmp=0`;
            let resp = await fetch(url);
            const recibos = await resp.json();
            this.setState({cargando:false, detalle:true, recibos, objNomina:mov });
        }else{
            let url = `${this.props.urlws}/nomina/cargarNomCalcTot/${emp}`;
            let resp = await fetch(url);
            const calculos = await resp.json();
            this.setState({cargando:false, detalle:true, calculos, objNomina:mov });
        }
    }
    cargarRecibos = async(tipoEmp) =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        const mov = this.state.objNomina;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/recibos?emp=${emp}&ejer=${ejer}&nom=${mov.Nomina}&tipo=${tipo}&todos=${tipo}&tipoEmp=${tipoEmp}`;
        let resp = await fetch(url);
        const recibos = await resp.json();
        this.setState({cargando:false,  recibos });
    }

    nuevoMov = () =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        if(emp === 0){
            Swal.fire('Atencion','Especifique la empresa','error');
            return;
        }
        if(emp === 'X'){
            Swal.fire('Atencion','Especifique el tipo de nomina','error');
            return;
        }
        if(ejer === 0){
            Swal.fire('Atencion','Especifique el año','error');
            return;
        }
        let nomina = 0;
        let fec = new Date();
        fec.addDays(15);
        let fechaFin = fec.yyyymmdd('-');
        let fechaIni = new Date().yyyymmdd('-');
        let nueva = true;
        this.setState({mostrarModal:true, fechaIni, fechaFin, nomina, nueva});
    }
    render(){
        const estilo1 = { display: this.state.detalle ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.detalle ? 'none' : 'inline' };
        const estilo3 = { display: this.state.calculando ? 'inline' : 'none' };
        return (
            <Container>
                <div style={estilo3} >
                    <br/>
                        <Progress value={this.state.progreso} color="success"/>
                    <br/>
                </div>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                <span></span>
                }
                <div style={estilo1}>
                    {this.state.objNomina.Cerrada ?
                        <Recibos recibos={this.state.recibos} nomina={this.state.objNomina} regresar={this.regresar} timbrar={this.timbrar} timbrarTodo={this.timbrarTodo} imprimir={this.imprimirRecibo} concentrado={this.imprimirConcentrado} acumulado={this.imprimirAcumulado} cargarRecibos={this.cargarRecibos} mostrarError={this.mostrarError}/>
                    :
                        <Calculos calculos={this.state.calculos} regresar={this.regresar} eliminar={this.eliminarEmp} verCalculo={this.verCalculoEmp}/>
                    }
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-4">
                            <FormGroup>
                                <span>Empresa</span><br />
                                <Input size="sm" type="select" name="empresa" value={this.state.empresa} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.empresas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Año</span><br />
                                <Input size="sm" type="select" name="ejercicio" value={this.state.ejercicio} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.ejercicios.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Tipo</span><br />
                                <Input size="sm" type="select" name="tipo" value={this.state.tipo} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="X">Especifique</option>
                                    {this.state.tipos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.nominas} height={500} width={990} onRowClick={this.editarMov} detalle={this.detalleMov} imprimir={this.imprimir} cambiarStatus={this.cambiarStatus} calcular={this.calcular} />
                </div>
                <Modal size="sm" id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} tabIndex={1}>
                    <ModalBody>
                        {this.state.nomina === 0 ?
                            <h5>Datos de la nueva nomina</h5>
                            :
                            <h5>Datos de la nomina {this.state.nomina} </h5>
                        }
                        <div className="col-2">

                        </div>
                        <div className="col-8">
                            <div className="row">
                                <span>Del dia</span>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </div>
                            <div className="row">
                                <span>Al dia</span>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </div>
                            {
                                this.state.nueva?
                                <span></span>
                                :
                                <div className="row">
                                <span>Fecha de pago</span>
                                <Input size="sm" type="date" name="fechaPago" value={this.state.fechaPago} onChange={this.onChange} />
                            </div>
                            }
                        </div>
                        <br/>
                        <div className="row">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color='success' size="sm" onClick={this.guardar}>Aceptar</Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color='danger' size="sm" onClick={this.handleClose}>Cancelar</Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal size="sm" id="dlgImp" name="dlgImp" isOpen={this.state.mostrarModalImp} toggle={this.handleCloseImp} tabIndex={1}>
                    <ModalBody>
                        <h6>Punto de pago</h6>
                        <div className="col">
                            <Input size="sm" type="select" name="punto" value={this.state.punto} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.puntos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.I}-{i.N}</option>
                                    )}
                            </Input>
                        </div>
                        <br/>
                        <div className="row">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color='success' size="sm" onClick={this.imprimirB}>Aceptar</Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color='danger' size="sm" onClick={this.handleCloseImp}>Cancelar</Button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal size="lg"  isOpen={this.state.mostrarCalculo} toggle={this.cerrarModalCalculo} >
                    <ModalHeader>
                                    <h6>{this.state.nomEmp}</h6>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ height:400, overflow: 'auto' }}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 30 }}>Id</th>
                                    <th>Nombre</th>
                                    <th className="numero" style={{ width: 100 }}>Unidades</th>
                                    <th className="numero" style={{ width: 100 }}>Percepciones</th>
                                    <th className="numero" style={{ width: 100 }}>Deducciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.calculo.map((o, i) => (
                                    <tr>
                                        <td className="numero" style={{ width: 30 }}>{o.Id}</td>
                                        <td>{o.N}</td>
                                        {o.T === "U" ?
                                        <td className="numero">{o.Importe.formato()}</td>:
                                        <td></td>
                                        }                                        
                                        {o.T === "P" ?
                                        <td className="numero">{o.Importe.formato()}</td>:
                                        <td></td>
                                        }                                        
                                        {o.T === "D" ?
                                        <td className="numero">{o.Importe.formato()}</td>:
                                        <td></td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        </div>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr>
                                    <td style={{ width: 30 }}></td>
                                    <td className="rojo">TOTALES</td>
                                    <td style={{ width: 100 }}></td>
                                    <td style={{ width: 100 }} className="numero negro">{this.state.per.formato()}</td>
                                    <td style={{ width: 100 }} className="numero rojo">{this.state.ded.formato()}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="rojo">TOTAL A PAGAR</td>
                                    <td></td>
                                    <td className="numero negro">{this.state.tot.formato()}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { movs } = this.props;
        return (
            <React.Fragment>
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50, textAlign:'center'}}>Nomina</th>
                        <th style={{width:80}}>Inicio</th>
                        <th style={{width:80}}>Fin</th>
                        <th style={{width:80}}>Pago</th>
                        <th style={{width:40, textAlign:'center'}}>Cerrada</th>
                        <th>Descripcion</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} detalle={this.props.detalle} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} calcular={this.props.calcular} />
                    ))}
                </tbody>
                </Table>
            </div>
            </React.Fragment>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        if(this.props.mov.Cerrada){
            Swal.fire('Atencion','Nomina cerrada, no se puede editar','error');
            return;
        }
        this.props.onRowClick(this.props.mov);
    }
    detalle = (e) =>{
        this.props.detalle(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    cambiar = (e) =>{
        this.props.cambiarStatus(this.props.mov);
    }
    calcular = (cve) => {
        this.props.calcular(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Nomina}</td>
            <td>{mov.Inicio}</td>
            <td>{mov.Fin}</td>
            <td>{mov.Pago}</td>
            <td style={{width:40, textAlign:'center'}}><input type="checkbox" checked={mov.Cerrada} /></td>
            <td>{mov.D}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/tabla.png" alt="empleados" title="empleados" onClick={this.detalle}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
            <td>
                {this.props.mov.Cerrada ?
                <span></span>
                :
                <img src="/imagenes/cotizar.png" alt="cerrar" title="calcular" onClick={this.calcular}/>
                }
            </td>
            <td>
                {this.props.mov.Cerrada ?
                <span></span>
                :
                <img src="/imagenes/candado.png" alt="cerrar" title="cerrar" onClick={this.cambiar}/>
                }
            </td>
            <td>{mov.Nomina}</td>
        </tr>
        );
    }
}
class Recibos extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            excel:false,
            tipo:0
        }
    }
    onChecked = (e) => {
        this.setState({[e.target.name] : e.target.checked});
    }
    onChange = (e) => {
        const campo = e.target.name;
        this.setState({[campo] : e.target.value});
        if(campo === "tipo")
           this.props.cargarRecibos(e.target.value);
    }
    render() {
        let { recibos } = this.props;
        return (
            <React.Fragment>
                <br/>
                <Row>
                    <Col-3>
                        <span>Recibos de pago de la nomina <b>{this.props.nomina.Nomina}</b></span>
                    </Col-3>
                    <Col>
                        <Button size="sm" color="danger" onClick={this.props.regresar}>Regresar</Button>
                    </Col>
                    <Col-2>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" color="warning" onClick={() => this.props.concentrado(this.state.tipo, this.state.excel)}>
                            <img src="/imagenes/printer.png" alt="imprimir" title="imprimir concentrado" />Imprimir concentrado
                        </Button>
                    </Col-2>
                    <Col-2>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" color="warning" onClick={() => this.props.acumulado(this.state.tipo, this.state.excel)}>
                            <img src="/imagenes/printer.png" alt="imprimir" title="imprimir acumulado" />
                            Imprimir acumulado
                        </Button>
                    </Col-2>
                    <Col>
                        <Input size="sm" type="select" name="tipo" value={this.state.tipo} onChange={this.onChange}>
                            <option key="0" value={0}>Todos</option>
                            <option key="1" value={1}>Eventuales</option>
                            <option key="2" value={2}>Planta</option>
                        </Input>

                    </Col>
                    <Col>
                        Excel &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" name="excel" checked={this.state.excel} onChange={this.onChecked} />
                    </Col>
                    <Col-2>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" color="warning" onClick={this.props.timbrarTodo}>
                            Timbrar
                        </Button>
                    </Col-2>
                </Row>  
            <br/>
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50, textAlign:'center'}}>Serie</th>
                        <th style={{width:50, textAlign:'center'}}>Folio</th>
                        <th style={{width:50, textAlign:'center'}}>No.</th>
                        <th>Empleado</th>
                        <th style={{width:100, textAlign:'right'}}>Importe</th>
                        <th style={{width:80}}>Fec.Pago</th>
                        <th style={{width:70}}>Timbrado</th>
                        <th>Jorn.</th>
                        <th>Reg.</th>
                        <th>Cont.</th>
                        <th style={{width:250}}>UUID</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {recibos.map((esc, i) => (
                        <ReciboRow key={i} mov={esc} onRowClick={this.onRowClick} detalle={this.props.detalle} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} timbrar={this.props.timbrar} mostrarError={this.props.mostrarError}/>
                    ))}
                </tbody>
                </Table>
            </div>
            </React.Fragment>
        );
    }
}
class ReciboRow extends React.Component{
    onClick = (e) =>{
        if(this.props.mov.Cerrada){
            Swal.fire('Atencion','Nomina cerrada, no se puede editar','error');
            return;
        }
        this.props.onRowClick(this.props.mov);
    }
    timbrar = (e) =>{
        this.props.timbrar(this.props.mov, true);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    mostrarError = (e) =>{
        this.props.mostrarError(this.props.mov);
    }
    cambiar = (e) =>{
        this.props.cambiarStatus(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Ser}</td>
            <td>{mov.Rec}</td>
            <td>{mov.Emp}</td>
            <td>{mov.Nom}</td>
            <td className="numero">{mov.Tot}</td>
            <td>{mov.Pago}</td>
            <td><input type="checkbox" checked={mov.Timb === 'S'} /></td>
            <td>{mov.Jor}</td>
            <td>{mov.Reg}</td>
            <td>{mov.Cont}</td>
            <td>
                {mov.Timb === 'S' ?
                    <span>{mov.Uuid}</span>
                :null
                }
                {mov.Timb === 'C' ?
                    <span>C A N C E L A D O</span>
                :null
                }
                </td>
            <td>
                {mov.Timb === 'S' ?
                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
                : null
                }
                {mov.Timb === 'N' || mov.Timb === 'X' ?
                    <img src="/imagenes/xml.png" alt="timbrar" title="timbrar" onClick={this.timbrar}/>
                    : null
                }
            </td>
            <td>
                {mov.Timb === 'S' ?
                    <img src="/imagenes/email.png" alt="enviar" title="enviar" onClick={this.enviar}/>
                :
                    <span>

                    </span>
                }
                {mov.Timb === 'X' ?
                    <img src="/imagenes/zoom.png" alt="enviar" title="ver error" onClick={this.mostrarError}/>
                :
                    <span>
                        
                    </span>
                }
            </td>
        </tr>
        );
    }
}
class Calculos extends React.Component {
    render() {
        let { calculos } = this.props;
        return (
            <React.Fragment>
            <span>Calculo de la nomina actual   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button size="sm" color="danger" onClick={this.props.regresar}>Regresar</Button></span>
            <br/><br/>
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50, textAlign:'center'}}>No.</th>
                        <th>Nombre</th>
                        <th style={{width:100, textAlign:'right'}}>Percepciones</th>
                        <th style={{width:100, textAlign:'right'}}>Deducciones</th>
                        <th style={{width:100, textAlign:'right'}}>Total</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {calculos.map((esc, i) => (
                        <CalculoRow key={i} mov={esc} onRowClick={this.props.eliminar} verCalculo={this.props.verCalculo} />
                    ))}
                </tbody>
                </Table>
            </div>
            </React.Fragment>
        );
    }
}
class CalculoRow extends React.Component{
    onClick = async(e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Eliminar de esta nomina al empleado " + this.props.mov.Nom +" ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
              if(result.value)
                this.props.onRowClick(this.props.mov);
          })        
    }
    verCalculo = async(e) =>{
        this.props.verCalculo(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Id}</td>
            <td>{mov.Nom}</td>
            <td className="numero">{mov.Per.formato()}</td>
            <td className="numero">{mov.Ded.formato()}</td>
            <td className="numero">{mov.Tot.formato()}</td>
            <td>
                <img src="/imagenes/tabla.png" alt="ver calculo" title="ver calculo" onClick={this.verCalculo}/>
            </td>
            <td>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}
